/* Footer Wrapper */
.footer {
    background-color: #1e1e2f; /* Dark background */
    color: #fff;
    padding: 50px 20px;
    font-family: Arial, sans-serif;
    margin-top: 20px;
  }
  
  /* Footer Container */
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 9%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Footer Logo Section */
  .footer-logo {
    flex: 1;
    min-width: 250px;
    margin-top: 21px;
  }
  
  .footer-logo h2 {
    font-size: 1.8rem;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .footer-logo p {
    margin: 5px 0;
    color: #888;
  }
  
  .social-links {
    margin-top: 15px;
  }
  
  .social-links a {
    margin-right: 15px;
    color: #888;
    font-size: 1.2rem;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .social-links a:hover {
    color: #e74c3c;
  }
  
  /* Footer Links Section */
  .footer-links {
    flex: 1;
    min-width: 200px;
  }
  
  .footer-links h4 {
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 15px;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
   
  }
  
  .footer-links ul li {
    margin-bottom: 10px;

  }
  
  .footer-links ul li a {
    margin-top: 18px;
    color: #888;
    text-decoration: none;
    transition: color 0.3s;
    line-height: 1.8;
  }
  
  .footer-links ul li a:hover {
    color: #e74c3c;
  }
  
  /* Footer Bottom */
  .footer-bottom {
    margin-top: 30px;
    text-align: center;
    font-size: 0.9rem;
    color: #888;
    border-top: 1px solid #333;
    padding-top: 15px;
  }
  