@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Rajdhani:wght@300;400;500;600;700&display=swap");

.top-rated-places {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
  /* margin-top: -12%; */
}
.top-rated-places h1 {
  position: absolute;
  left: 50%;
  opacity: 0.12;
  letter-spacing: 1px;
  font-family: "Great Vibes", cursive;
  transform: translate(-50%, -25px);
  -webkit-transform: translate(-50%, -18px);
  margin-top: 19px;
  font-size: 54px;
}
.top-rated-places h2 {
  color: #2d3954;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Raleway", sans-serif;
  font-size: 44px;
}
.theme-cl {
  color: #ed8708;
}

.card-main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.places-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 34px;
  max-width: 1300px;
}

.place-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  padding: 15px;
  text-align: left;
}

.place-image {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.Reveal-listing-price-info {
  position: absolute;
  top: 25px;
  left: 25px;
  display: inline-block;
  border-radius: 50px;
  font-size: 14px;
}
.pricetag {
  display: inline-block;
  background: #ffffff;
  padding: 4px 18px;
  border-radius: 50px;
  font-size: 14px;
  margin-right: 15px;
  color: #505667;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.2);
}

.status-open {
  background-color: #4eb400;
  float: left;
  position: absolute;
  transform: rotate(45deg);
  right: -64px;
  top: 22px;
  text-align: center;
  width: 200px;
  font-size: 12.5px;
  margin: 0;
  z-index: 2;
  color: #fff;
  font-weight: 500;
  line-height: 28px;
}

.status-closed {
  background: #e42133;
  float: left;
  position: absolute;
  transform: rotate(45deg);
  right: -64px;
  top: 22px;
  text-align: center;
  width: 200px;
  font-size: 12.5px;
  margin: 0;
  z-index: 2;
  color: #fff;
  font-weight: 500;
  line-height: 28px;
}

.lup-category {
  position: absolute;
  z-index: 1;
  left: 28px;
  top: 42%;
}

.lup-category p {
  margin: 5px;
  padding: 8px 20px;
  background: #ea1b41;
  color: #ffffff !important;
  border-radius: 50px;
  margin-left: 0;
}

h3 {
  margin: 10px 0;
  font-size: 1.2rem;
}

.rating {
  font-size: 0.9rem;
  color: #ff9800;
  margin-bottom: 10px;
}
.overall-reviews {
  color: #516175;
  font-weight: 400;
  margin-left: 5px;
  position: relative;
  top: -1px;
  font-size: 12px;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.card-footer {
  display: flex;
  align-items: center;
  width: 100%;
  /* padding: 1rem 1.5rem; */
  border-top: 1px solid #e3e3ea;
  justify-content: space-between;
}

.user-days-name {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.user-name {
  font-size: 14px;
  line-height: 1.2;
}
.user-days {
  display: block;
  font-weight: 400;
  font-size: 13px;
  color: #6d7e9c;
}
.view-button {
  padding: 0 10px;
  height: 26px;
  display: flex;
  align-items: center;
  background: transparent;
  color: #ea1b41;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #ea1b41;
  transition: all ease 0.4s;
}

.status.open {
  color: green;
  font-weight: bold;
}

.status.closed {
  color: red;
  font-weight: bold;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  align-items: center;
}

.pagination button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #0056b3;
}

.read-more-button-listing {
  border: none;
  background: none;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  margin-left: 5px;
}

.read-more-button-listing:hover {
  color: #0056b3;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Adjust as needed */
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Tablets (768px and below) */
@media (max-width: 768px) {
  .top-rated-places h1 {
    font-size: 44px;
  }

  .top-rated-places h2 {
    font-size: 32px;
  }

  .places-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }

  .place-card {
    padding: 10px;
  }

  .pricetag {
    font-size: 12px;
    padding: 4px 10px;
  }

  .status-open,
  .status-closed {
    font-size: 11px;
    width: 150px;
  }

  .view-button {
    padding: 5px 10px;
    font-size: 11px;
  }
}

/* Mobile Devices (480px and below) */
@media (max-width: 480px) {
  .top-rated-places h1 {
    font-size: 36px;
  }

  .top-rated-places h2 {
    font-size: 28px;
  }

  .places-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .place-card {
    padding: 8px;
  }

  h3 {
    font-size: 1rem;
  }

  .pricetag {
    font-size: 11px;
    padding: 3px 8px;
  }

  .status-open,
  .status-closed {
    font-size: 10px;
    width: 225px;
  }

  .view-button {
    padding: 3px 8px;
    font-size: 10px;
  }
}

/* Small Mobile Devices (360px and below) */
@media (max-width: 360px) {
  .top-rated-places h1 {
    font-size: 28px;
  }

  .top-rated-places h2 {
    font-size: 24px;
  }

  .places-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .place-card {
    padding: 5px;
  }

  .pricetag {
    font-size: 10px;
    padding: 2px 6px;
  }

  .status-open,
  .status-closed {
    font-size: 9px;
    width: 100px;
  }

  .view-button {
    padding: 2px 5px;
    font-size: 9px;
  }
}