/* General Styling */
.how-it-works {
    text-align: center;
    padding: 50px 20px;
    background-color: #fff;
  }
  
  .how-it-works h1{
    position: absolute;
    left: 50%;
    opacity: 0.12;
    letter-spacing: 1px;
    font-family: "Great Vibes", cursive;
    transform: translate(-50%, -25px);
    -webkit-transform: translate(-50%, -18px);
    margin-top: 12px;
    font-size: 58px;
  }

  .how-it-works h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #19365f;
    margin-bottom: 40px;
    position: relative;
  }
  
  .how-it-works h2 span {
    color: #ed8708;
  }
  
  .steps-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    flex-wrap: wrap;
  }
  
  /* Individual Step Cards */
  .step {
    text-align: center;
    position: relative;
    max-width: 300px;
    flex: 1;
  }
  
  .icon-box {
    position: relative;
    width: 85px;
    height: 85px;
    margin: 0 auto 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 2rem;
    z-index: 2;
  }
  
  .icon-box svg {
    font-size: 2.5rem;
    color: #00796b;
    z-index: 2;
  }
  
/* Decorative ::before for Icon */
.icon-decoration {
    content: "";
    position: absolute;
    width: 85px;
    height: 85px;
    border-radius: 20px;
    transform: rotate(45deg);
    top: 0;
    left: 0;
    z-index: 1;
  }

  .step h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #2D3954;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Raleway', sans-serif;
    line-height: 26px;
  }
  
  .step p {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 1.8;
    font-family: sans-serif;
    color: gray;
  }
  
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
  }
  
  .arrow-image {
    width: 30px;
    height: 30px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .steps-container {
      flex-direction: column;
      align-items: center;
    }
  
    .arrow {
      display: none;
    }
  
    .step {
      max-width: 100%;
    }
  }
  