.add-listing-page {
  /* padding: 20px; */
}

.addlisting-header {
  position: relative;
  background: url("/public/images/banner-3.jpg") no-repeat center center/cover;
  color: #fff;
  padding: 40px 20px;
  z-index: 0;
  text-align: justify;
}

/* Ensure the text is above the overlay */
.addlisting-header h1,
.addlisting-header p {
  position: relative;
  z-index: 1;
  margin-left: 30px;
}
.addlisting-header h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}
.addlisting-header p {
  font-size: 20px;
  font-family: initial;
  font-style: italic;
}

.page-title {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

.page-subtitle {
  font-size: 1rem;
  color: #666;
  text-align: center;
  margin-bottom: 20px;
}

.add-listing-form {
  max-width: 700px;
  margin: 0 auto;
  /* background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.form-section-general {
  background: #ebebeb;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  margin-top: 18px;
}

.form-section-address {
  background: #ebebeb;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  margin-top: 18px;
}

.form-section-images {
    background: #ebebeb;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    margin-top: 18px;
  }

  .form-section-social {
    background: #ebebeb;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    margin-top: 18px;
  }

  .form-section-hours {
    background: #ebebeb;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    margin-top: 18px;
    /* display: grid; */
    /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
    /* gap: 15px; */
  }

.general-section {
  position: relative;
  display: flex;
  /* align-items: center; */
  /* padding: 1rem 1.5rem; */
  border-bottom: 1px solid #d2d7e0;
  flex-direction: column;
  margin-bottom: 10px;
}

.form-section h2 {
  font-size: 1.2rem;
  color: #444;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 5px;
}

.form-group select{
    color: #2b2b2b;
    width: 98%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    background-color: #fdfdfd7d;
    margin-bottom: 10px;
}

.form-group input,
/* .form-group select, */
.form-group textarea {
  width: 96%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #fdfdfd7d;
  margin-bottom: 10px;
}

.form-group textarea {
  resize: none;
  height: 100px;
}

.error-message {
  color: red;
  font-size: 0.8rem;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #ed8708;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #ffcd91;
  color: #333;
}

/* Custom Checkbox Styling */
.custom-checkbox {
    display: flex;
    /* align-items: center; */
    gap: 10px;
  }
  
  .custom-checkbox input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;
    margin-bottom: -5px;
  }
  
  .custom-checkbox input[type="checkbox"]:checked {
    border-color: #4caf50;
    background-color: #4caf50;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='M6.173 11.593l-3.7-3.7 1.414-1.414 2.286 2.286 5.1-5.1 1.415 1.414-6.515 6.514z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
  }
  
  .custom-checkbox label {
    font-size: 16px;
    color: #555;
    cursor: pointer;
  }
  
  .custom-checkbox input[type="checkbox"]:hover {
    border-color: #4caf50;
  }

    /* .form-section-social {
      margin: 20px 0;
      background-color: #f9f9f9;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    } */
  
  /* .form-section-social h2 {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
  }
   */
  .form-section-social h2 i {
    margin-right: 8px;
    color: #007bff;
  }
  
  .social-accounts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  /* .form-group label {
    font-size: 14px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    color: #555;
  } */
  
  /* .form-group label i {
    margin-right: 8px;
    font-size: 16px;
    color: #666;
  } */
  
  /* .form-group input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  } */
  
  .form-group input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
  }
  