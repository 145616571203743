/* Header Section */
.about-header {
    position: relative;
    background: url("https://themezhub.net/reveal-live/reveal/assets/img/airbnb-banner.jpg")
      no-repeat center center/cover;
    color: #fff;
    text-align: justify;
    padding: 40px 20px;
    z-index: 0;
  }
  
  .about-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(25, 54, 95, 0.7); 
    z-index: -1; 
  }
  
  .about-header h1,
  .about-header p {
    position: relative;
    z-index: 1;
    margin-left: 15%;
  }
  
  .about-header h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .about-header p {
    font-size: 20px;
    font-family: initial;
    font-style: italic;
  }
  
  
  /* Content Section */
  .about-content {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 20px;
    gap: 30px;
    align-items: center;
    justify-content: center;
  }
  
  .content-left {
    position: relative;
    max-width: 600px;
  }
  .content-left::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #1c1d23;
    opacity: 0.3;
  }
  
  .about-image {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ed8708;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  .play-button:hover {
    background-color: #ed8708;
  }
  
  .content-right {
    max-width: 500px;
  }
  
  .content-right h4 {
    color: #01b299 !important;
    line-height: 20px;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  
  .content-right h2 {
    color: #19365f;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .content-right p {
    color: #666;
    font-size: 17px;
    line-height: 1.8;
    margin-bottom: 20px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
  }
  
  .read-more-button {
    background-color: #ed8708;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .read-more-button:hover {
    background-color: #ffc47c;
    color: #1c1d23;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-content {
      flex-direction: column;
      text-align: center;
    }
  
    .content-right {
      max-width: 100%;
    }
  
    .about-image {
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .about-header h1 {
      font-size: 2rem;
    }
  
    .about-header p {
      font-size: 1rem;
    }
  
    .content-right h2 {
      font-size: 1.5rem;
    }
  
    .read-more-button {
      font-size: 0.9rem;
    }
  }
  