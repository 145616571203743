@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Rajdhani:wght@300;400;500;600;700&display=swap");


/* SliderComponent.css */
.CategoryCarousel{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    flex-direction: column;
}

.CategoryCarousel h2{
  font-size: 42px;
}

.CategoryCarousel h1{
  position: absolute;
  left: 50%;
  opacity: 0.12;
  letter-spacing: 1px;
  font-family: "Great Vibes", cursive;
  transform: translate(-50%, -25px);
  -webkit-transform: translate(-50%, -18px);
  margin-top: 19px;
  font-size: 54px;
  bottom: -12%;
}
.slider-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .slick-slide {
    padding: 10px;
  }
  
  .card {
    border: 1px solid #eee;
    /* border-radius: 10px; */
    padding: 20px;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .card:hover {
    background-color: #ed8708;
    color: #ffffff;

    .card-title{
        color: #ffff !important;
    }
    .card-icon{
        color:#ffff;
    }
    .card-listings{
        color: white;
    }
  }
  /* .card-title:hover{
    color: #ffff !important;
  } */
  
  .card-icon {
    font-size: 2.5rem;
    color: #ed8708;
    margin-bottom: 15px;
  }
  
  .card-title {
    font-size: 1.2rem;
    color: #333;
    margin: 10px 0;
    font-weight: 500;
  }
  
  .card-listings {
    font-size: 12px;
    padding: 7px 20px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.05);
  }
  .slick-prev, .slick-next{
    /* color: #ed8708 !important; */
    background: #e3cfd673 !important;
    margin-top: 30px;
    padding: 10px;
  }

  .slick-dots li button:before {
    font-size: 10px;
    color: #413b3b;
  }
  
  .slick-dots li.slick-active button:before {
    color: #ed8708;
  }
  
  /* button.slick-arrow.slick-prev {
    color: black;
    background-color: gray !important;
} */

/* Tablets (768px and below) */
@media (max-width: 768px) {
  .CategoryCarousel h1 {
    font-size: 44px;
    /* bottom: -8%; */
  }

  .CategoryCarousel h2 {
    font-size: 32px;
  }

  .card {
    padding: 15px;
  }

  .card-icon {
    font-size: 2rem;
  }

  .card-title {
    font-size: 1rem;
  }

  .card-listings {
    font-size: 10px;
  }
}

/* Mobile Devices (480px and below) */
@media (max-width: 480px) {
  .CategoryCarousel h1 {
    font-size: 36px;
    bottom: -10%;
  }

  .CategoryCarousel h2 {
    font-size: 28px;
  }

  .slider-container {
    padding: 10px;
  }

  .card {
    padding: 10px;
  }

  .card-icon {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  .card-title {
    font-size: 0.9rem;
  }

  .card-listings {
    font-size: 9px;
    padding: 5px 10px;
  }
}