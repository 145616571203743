.contact-us {
  background-color: #f9f9f9;
  /* padding: 50px 20px; */
  text-align: center;
}

/* Header Section */
.contact-header {
  position: relative;
  background: url("https://themezhub.net/reveal-live/reveal/assets/img/airbnb-banner.jpg") no-repeat center center/cover;
  color: #fff;
  padding: 50px 20px;
  z-index: 0;
  text-align: justify;
}

.contact-header::before {
  content: "";
  position: absolute;
  background: rgba(25, 54, 95, 0.7); /* Use RGBA for transparency */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1; /* Places it below the content */
  border-radius: inherit; /* Ensures it matches the container's shape, if rounded */
}

/* Ensure the text is above the overlay */
.contact-header h1,
.contact-header p {
  position: relative;
  z-index: 1;
  margin-left: 30px;
 
}
.contact-header h1{
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
}
.contact-header p{
  font-size: 20px;
  font-family: initial;
  font-style: italic;
}

/* Contact Options */
.contact-options {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 30px; /* To overlap the header section slightly */
}

.contact-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: center;
}

.contact-icon {
  font-size: 2.5rem;
  color: #e74c3c;
  margin-bottom: 15px;
}

.contact-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
}

.contact-card p {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 5px;
}

.live-chat-button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
}

.live-chat-button:hover {
  background-color: #43a047;
}

.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  margin-top: 5%;
}

.contact-form {
  width: 100%;
  max-width: 500px;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  /* font-weight: bold; */
  margin-bottom: 5px;
  color: #495e96;
  font-weight: 400;
  text-align: justify;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #9b9595;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  /* font-weight: bold; */

}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #aaa;
}

.form-group textarea {
  height: 100px;
  resize: none;
}

.submit-button {
  display: block;
  width: 100%;
  background: #e91e63;
  color: #fff;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background: #c2185b;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-header {
    padding: 30px 10px;
    text-align: center;
  }

  .contact-header h1 {
    font-size: 1.8rem;
  }

  .contact-header p {
    font-size: 16px;
  }

  .contact-options {
    flex-direction: column;
    align-items: center;
  }

  .contact-card {
    width: 90%;
  }

  .contact-form-container {
    padding: 10px;
  }

  .contact-form {
    padding: 20px;
  }

  .submit-button {
    font-size: 16px;
    padding: 8px 15px;
  }
}

@media (max-width: 480px) {
  .contact-header h1 {
    font-size: 1.5rem;
  }

  .contact-header p {
    font-size: 14px;
  }

  .contact-card {
    padding: 15px;
  }

  .contact-icon {
    font-size: 2rem;
  }

  .live-chat-button {
    font-size: 0.8rem;
    padding: 8px 12px;
  }

  .submit-button {
    font-size: 14px;
    padding: 8px 10px;
  }
}