body {
  font-family: "Roboto", sans-serif !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 2.5em 3em;
  width: 500px;
  border-radius: 8px;
  position: relative;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #888;
}

.modal-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: gray;
  text-align: center;
}

.highlight {
  color: #ed8708;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.login-container {
  width: 500px;
  /* background: rgba(255, 252, 249, 0.874); */
  border: 1px solid #ddd;
  /* border-radius: 5px; */
  font-size: 16px;
  color: #333;
  padding: 20px ;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */

  background: rgb(243 239 235 / 87%);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgb(28 27 27 / 22%);

}

.input-group {
  margin-bottom: 1rem;
  text-align: left;
}

.input-group label {
  display: block;
  font-size: 0.9rem;
  color: #495e96;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

.input-group input {
  width: 95%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f3f3f3;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #ed8708;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.or-text {
  margin: 1rem 0;
  color: #888;
  text-align: center;
}

.social-login {
  display: flex;
  justify-content: center;
  /* gap: 1rem; */
}

.social-button {
  background-color: #ddd;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;    
}



.social-button.google {
  background-color: #ed8708;
}



.footer-text {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: gray;
  text-align: center;
}

.footer-text a {
  color: #ed8708;
  text-decoration: none;
}
.social-login img{
  height: 60px;
  cursor: pointer;
}
