.blog-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    margin-top: -9%;
  }
  .blog-container h1{
    position: absolute;
    left: 50%;
    opacity: 0.12;
    letter-spacing: 1px;
    font-family: "Great Vibes", cursive;
    transform: translate(-50%, -25px);
    -webkit-transform: translate(-50%, -18px);
    margin-top: 14px;
    font-size: 58px;
  }
  
  .latest-articles {
    color: #2D3954;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Raleway', sans-serif;
    font-size: 44px;
    text-align: center;
  }
  .latest-articles span{
    color: #ed8708;
  }
  
  .blogs {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
  }
  
  .blog-card {
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    width: 30%;
    height: 470px;
  }
  
  
  
  .blog-thumbnail img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blog-content {
    padding: 5px 20px 15px;
  }
  
  .author {
    font-size: 14px;
    font-weight: 600;
    color: #333c56;
    font-family: 'Roboto', sans-serif;
  }
  
  .blog-title {
    color: #2D3954;
    font-weight: 500;
    text-transform: capitalize;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    margin-bottom: 4px;
    line-height: 26px;
  }
  .blog-title:hover{
    color: #ed8708;
  }
  .summary {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.8;
    
  }
  .continue-title{
    color: #292727;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .continue-reading {
    color: #e63946;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .continue-reading:hover {
    text-decoration: underline;
  }
  
  .blog-meta {
    /* background: rgba(9, 178, 228, 0.1); */
    color: #79bb2c;
  }
  
  .date,
  .read-time,
  .tag {
    margin-right: 10px;
  }
  
  .tag {
    background: #ddd;
    border-radius: 5px;
    padding: 2px 8px;
  }

  .blog-detail-container {
    padding: 20px;
  }
  
  .blog-detail-container h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .blog-detail-container .author {
    color: gray;
    font-size: 14px;
  }
  
  .blog-detail-container .content {
    margin-top: 20px;
  }
  
  .tags {
    margin-top: 15px;
  }
  
  .tag {
    background-color: #f0f0f0;
    padding: 5px;
    margin-right: 10px;
    border-radius: 5px;
  }
  
  
  @media screen and (max-width: 768px) {
    .blog-card {
      width: 45%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .blog-card {
      width: 100%;
    }
  }  