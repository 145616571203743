/* src/components/SearchBar/SearchBar.css */

.searchbar-main {
  position: relative;
  color: #fff;
  background: url("/public/images/banner-3.jpg") center center / cover no-repeat;
  min-height: 760px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.searchbar-main::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay for opacity */
  z-index: 1;
}

.searchbar-main .big-header-capt {
  position: relative;
  z-index: 2; /* Ensure the text is above the overlay */
  text-align: center;
}

.big-header-capt h1 {
  font-weight: 700;
  margin: 0 auto;
  margin-bottom: 50px;
  text-align: center;
  font-size: 4.142em;
}

.search-bar {
  position: relative;
  z-index: 2; /* Ensure search bar is above the overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background: #ffffff;
  padding: 7px;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.2);
  overflow: inherit;
  flex-wrap: wrap;
}

.search-field,
.location-field,
.category-field {
  border-radius: 3px;
  padding-left: 40px;
  height: 52px;
  background: #ffffff;
  box-shadow: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-width: 250px;
}

.search-field,
.location-field {
  border-right: 1px solid #e0e7ec;
}

.category-field {
  margin-right: 20px;
}

.search-field input,
.location-field input,
.category-field select {
  border: none;
  outline: none;
  font-size: 1rem;
  padding-left: 0.5rem;
  flex-grow: 1;
}

.icon {
  color: #ed8708;
  margin-right: 0.5rem;
}

.search-button {
  background: #ed8708;
  color: #fff;
  border: none;
  padding: 0.75rem 2.5rem;
  border-radius: 2px;
  cursor: pointer;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categories-list {
  display: flex;
  gap: 15px;
  padding: 0;
  list-style-type: none;
}

.category-item {
  display: inline-block;
  color: #ffffff;
  padding: 6px 18px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.2);
  font-size: 15px;
  z-index: 1;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.category-item:hover {
  background-color: #fff;
  color: #ed8708;
  transform: scale(1.05);
}

@media (max-width: 1024px) {
  .big-header-capt h1 {
    font-size: 3rem; /* Smaller heading size for tablet */
  }
}

@media (max-width: 768px) {
  .search-bar {
    flex-direction: column;
    padding: 1rem;
  }}

  @media (max-width: 480px) {
    .big-header-capt h1{
      font-size: 40px;
    }
    .categories-list{
      gap: 10px;
    }
    .category-item{
      font-size: 12px;
    }
  }