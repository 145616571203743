.testimonational-main {
  display: flex;
  justify-content: center;
  max-width: 1900px;
  background-color: #f9f9f9;
  height: 800px;
}
.testimonials-section {
  padding: 50px 20px;
  text-align: center;
  font-family: Arial, sans-serif;
  max-width: 1200px;
}

.testimonials-section h1 {
  position: absolute;
  left: 50%;
  opacity: 0.12;
  letter-spacing: 1px;
  font-family: "Great Vibes", cursive;
  transform: translate(-50%, -25px);
  -webkit-transform: translate(-50%, -18px);
  margin-top: 22px;
  font-size: 58px;
}

.section-title {
  font-size: 44px;
  margin-bottom: 30px;
  color: #333;
}

.section-title span {
  color: #ed8708;
}

.testimonials-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.testimonial-card {
  /* text-align: center; */
  background: #ffffff;
  /* padding: 0 1rem; */
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
  margin: 10px 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 400px;
}

.testimonial-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 15px;
  border: 4px solid #d1cece;
}

.testimonial-name {
  color: #2d3954;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Raleway", sans-serif;
}

.testimonial-position {
  font-family: "Lora", serif;
  font-style: italic;
  font-size: 15px;
  color: #ed8708;
  margin-top: -6px;
}

.testimonial-feedback {
  font-size: 17px;
  color: #666;
  line-height: 1.8;
  max-width: 300px;
}
