.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%  ;
  background-color: #f9f9f9;
}

.register-box {
  width: 400px;
  background: rgb(243 239 235 / 87%);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgb(28 27 27 / 22%);
  padding: 30px;
  text-align: center;
  margin-top: 20px;

}

.register-header {
  margin-bottom: 20px;
}

/* .register-icon {
    background: #000;
    color: #e91e63;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 2rem;
    position: relative;
  } */

.register-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.register-icon img {
  height: 65px;
  width: 37%;
}

.register-form .form-group {
  margin-bottom: 15px;
  text-align: left;
}

.register-form .form-group label {
  display: block;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.register-form .form-group input {
  width: 94%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
}

.register-form .form-group input::placeholder {
  color: #aaa;
}

.register-form .form-footer {
  margin-top: 20px;
  text-align: center;
}

.register-form .form-footer p {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: gray;
    text-align: center;
}

.register-form .form-footer p a {
  color: #ed8708;
  text-decoration: none;
}

.register-form .form-footer p a:hover {
  text-decoration: underline;
}

.register-btn {
  width: 100%;
  padding: 10px;
  background-color: #ed8708;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.register-btn:hover {
  background-color: #ed8708;
}

.social-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social-login p {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: gray;
    text-align: center;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-icon {
  width: 40px;
  height: 40px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 16px;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.social-icon.facebook:hover {
  background-color: #3b5998;
  color: #fff;
}

.social-icon.google:hover {
  background-color: #db4a39;
  color: #fff;
}

.social-icon.twitter:hover {
  background-color: #1da1f2;
  color: #fff;
}
.error-message{
    color: red;
    font-size: 12px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .register-box {
      padding: 20px;
    }
  
    .register-form .form-group input {
      font-size: 14px;
    }
  
    .register-btn {
      font-size: 14px;
    }
  
    .social-login p {
      font-size: 0.8rem;
    }
  
    .social-icon {
      width: 35px;
      height: 35px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .register-box {
      padding: 15px;
    }
  
    .register-form .form-group input {
      font-size: 12px;
      padding: 8px;
    }
  
    .register-btn {
      font-size: 12px;
      padding: 8px;
    }
  
    .social-login p {
      font-size: 0.7rem;
    }
  
    .social-icon {
      width: 30px;
      height: 30px;
      font-size: 12px;
    }
  }