.business-listing {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.business-listing-page h1{
  text-align: center;
  font-size: 27px;
  color: coral;

}

.overlay {
  background-color: rgb(255, 255, 255); /* Dark semi-transparent overlay */
  border-radius: 15px;
  width: 90%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  /* justify-content:center; */
  padding: 20px;
  flex-wrap: wrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: rgb(108, 108, 108);
  flex-direction: column;
}

.profile-section {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 200px;
  background: #606060;
  color: #fff3cf;
  width: 100%;
  justify-content: center;
  border-radius: 10px;
  position: relative;
  /* color: #fff; */
  /* flex: 1 1 40%;
     */
}

.profile-section::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #222e4c;
  opacity: 0.6;
  /* z-index: -1; */
}

.homebanner-title {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.business-title-below {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

label.upload-background {
    position: absolute;
    right: 0px;
    bottom: -1px;
}

.upload-background span{
    position: absolute;
    bottom: -5px;
    right: -5px;
    background-color: #ff6347;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex
;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid #fff;
}

.logo-container {
  display: inline-block;
  max-width: 100px;
  height: 100px;
  padding: 5px;
  border-radius: 2px;
  box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.4);
  background: #ffffff;
  vertical-align: middle;
  margin-right: 15px;
  position: relative;
  top: 0px;
}

.upload-icon {
  position: absolute;
  bottom: -5px;
  right: -5px;
  background-color: #ff6347;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #fff;
}

.upload-icon:hover {
  background-color: #e5533d;
}

.profile-logo {
  width: 100px;
  height: 100px;
  /* border-radius: 50%; */
  border: 2px solid #fff;
}

.profile-info {
  text-align: left;
}

.profile-info h1 {
  font-size: 1.8em;
  margin: 0;
  color: #fff;
}

.profile-info .category {
  font-size: 1.2em;
  color: #ff6347;
  font-weight: bold;
  margin: 5px 0;
}

.profile-info p {
  font-size: 1em;
  margin: 5px 0;
  line-height: 1.4;
}

.description-box {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 16px auto;
  }
  .description-box h3{
  color: #ff6347;

  }

.details-section {
  background-color: rgba(247, 245, 245, 0.777);
  padding: 20px;
  border-radius: 10px;
  display: grid; /* Enable grid layout */
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Responsive grid */
  gap: 20px; /* Add spacing between grid items */
  width: 96%;
  box-sizing: border-box; /* Ensure padding is included in width/height */
}

.details-section h2 {
  font-size: 1.4em;
  margin-bottom: 10px;
  color: #ff6347;
}

.details-section p {
  margin: 5px 0;
  font-size: 1em;
  line-height: 1.4;
}

.details-section ul {
  list-style: none;
  padding: 0;
}

.details-section li {
  margin: 5px 0;
}

.details-section a {
  color: #747170;
  text-decoration: none;
  font-weight: bold;
}

.details-section a:hover {
  text-decoration: underline;
}

.error {
  color: red;
  font-size: 1.5em;
  text-align: center;
  margin-top: 50px;
}

.loading {
  font-size: 1.5em;
  text-align: center;
  margin-top: 50px;
}

.contact-box {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px #0000001a;
  padding: 20px;
}

.social-box {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px #0000001a;
  padding: 20px;
}
.business-box {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px #0000001a;
  padding: 20px;
}

.approval-message {
  color: red;
  font-size: 25px;
  text-align: center;
}
.rejection-message{
  color: red;
  font-size: 20px;
  text-align: center;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .overlay {
    flex-direction: column;
    text-align: center;
    padding: 15px;
  }

  .profile-section {
    flex-direction: column;
    text-align: center;
    /* padding: 10px; */
    height: 100%;
    gap: 1px;
    line-height: 0;
  }

  .logo-container {
    margin-top: 10px;
   
  }
  .logo-container h1 {
    font-size: 12px;
  }
  .profile-info h1 {
    font-size: 1.4em;
  }

  .profile-info .category {
    font-size: 1.1em;
  }

  .details-section {
    margin-top: 15px;
    padding: 15px;
  }

  .details-section h2 {
    font-size: 1.2em;
  }

  .details-section p {
    font-size: 0.9em;
    text-align: left;
  }
}

/* Extra Small Devices */
@media (max-width: 480px) {
  .profile-logo {
    width: 80px;
    height: 80px;
  }

  .upload-icon {
    width: 15px;
    height: 15px;
    font-size: 14px;
  }

  .profile-info h1 {
    font-size: 1.2em;
  }
  .logo-container{
    height: 80px;
  }

  .profile-info .category {
    font-size: 1em;
  }
  .business-title-below p{
    display: none ;
  }

  .details-section {
    /* padding: 10px; */
  }

  .details-section h2 {
    font-size: 1em;
  }

  .details-section p {
    font-size: 0.85em;
    text-align: left;
  }
}
