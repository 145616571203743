/* src/components/layout/Header.css */

.header {
  position: relative;
  color: #fff;
}

.header-top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 2rem;
  background: #0b676e;
}

.logo {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
}

.navigation ul {
  display: flex;
  list-style: none;
  gap: 1.5rem;
}

.navigation a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.header-actions .sign-in,
.header-actions .add-listing {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;


}
.header-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}
.sign-in{
    display: flex;
    justify-self: center;
    align-items: center;
    gap: 5px;
}

/* .header-actions .add-listing {
  background: #ed8708;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  margin-left: 1rem;
} */

.add-listing{
    border-radius: 50px;
    min-width: 150px;
    /* top: 15px; */
    position: relative;
    height: 50px;
    /* background: transparent;
    border: 2px solid #ea1b41 !important; */
    transition: all ease 0.4s;
    background: #ed8708 !important;
    border: 2px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
   
}

.profile-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c7a67438;    
  color: #fff; /* White text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 10px ;
  font-size: 16px; /* Font size for text */
  font-weight: bold; /* Bold text */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  gap: 8px; /* Space between icon and text */
}

.profile-icon svg {
  font-size: 20px; /* Size of the icon */
}

.profile-icon:hover {
  background-color: #388e3c; /* Darker green on hover */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

.profile-icon:active {
  background-color: #2e7d32; /* Even darker green on click */
  box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.3); /* Inset shadow on click */
}


@media (max-width: 786px) {
   .navigation{
    display: none;
   }           
}

@media (max-width: 450px) {
  .add-listing{
    width: 60px;
  }
  
}

@media (max-width: 768px) {
  .add-listing {
    min-width: 120px;
    height: 45px;
    font-size: 0.9rem; 
    gap: 4px; 
    padding: 0 8px;
  }
}

@media (max-width: 480px) {
  .add-listing {
    min-width: 75px;
    height: 36px;
    font-size: 0.7rem !important;
    border-radius: 7px;
    gap: 2px;
    padding: 0 5px;
  }
}