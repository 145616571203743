/* Business Detail Page Styling */

.business-detail-page {
      max-width: 900px;
      margin: 0 auto;
      padding: 20px;
      background-color: #f9f9fb;
      border-radius: 8px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      font-family: 'Arial', sans-serif;
    }
    
    .business-header {
      text-align: center;
      margin-bottom: 30px;
    }
    
    .business-logo {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 50%;
      margin-bottom: 15px;
      border: 3px solid #ddd;
    }
    
    .business-header h1 {
      font-size: 28px;
      color: #333;
      margin: 10px 0;
    }
    
    .business-header p {
      font-size: 16px;
      color: #555;
    }
    

    
    /* Section Headings */
    .business-detail-page h2 {
      font-size: 22px;
      color: #333;
      margin-bottom: 15px;
      border-left: 4px solid #007bff;
      padding-left: 10px;
    }
    
    /* Description Section */
    .business-description {
      margin-bottom: 30px;
      line-height: 1.6;
      color: #555;
    }
    
    .business-description p {
      font-size: 16px;
      margin: 0;
    }
    
    /* Gallery Section */
    .business-gallery {
      margin-bottom: 30px;
    }
    
    .gallery-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: 10px;
    }
    
    .gallery-image {
      width: 100%;
      height: 120px;
      object-fit: cover;
      border-radius: 8px;
      transition: transform 0.3s;
    }
    
    .gallery-image:hover {
      transform: scale(1.05);
    }
    
    /* Contact Information */
    .business-contact {
      margin-bottom: 30px;
    }
    
    .business-contact p {
      margin: 5px 0;
      font-size: 16px;
      color: #555;
    }
    
    .business-contact a {
      color: #007bff;
      text-decoration: none;
    }
    
    .business-contact a:hover {
      text-decoration: underline;
    }
    
    /* Social Media Links */
    .business-social ul {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      padding: 0;
      margin: 0;
    }
    
    .business-social ul li {
      list-style: none;
    }
    
    .business-social ul li a {
      font-size: 16px;
      color: #007bff;
      text-decoration: none;
      transition: color 0.3s;
    }
    
    .business-social ul li a:hover {
      color: #0056b3;
    }
    
    /* Business Timing */
    .business-timing ul {
      list-style: none;
      padding: 0;
    }
    
    .business-timing ul li {
      margin: 5px 0;
      font-size: 16px;
      color: #555;
    }
    
    /* Responsive Design */
    @media (max-width: 768px) {
      .business-header h1 {
        font-size: 24px;
      }
    
      .business-header p {
        font-size: 14px;
      }
    
      .business-detail-page h2 {
        font-size: 20px;
      }
    
      .business-contact p {
        font-size: 14px;
      }
    
      .gallery-image {
        height: 100px;
      }
    }
    